<template>
  <div>
    <div class="home" ref="bigbox">
      <div class="zs-banner">
        <!-- <img src="../assets/homeimg/banner.png" alt="" /> -->
      </div>
      <div class="banner-txt">
        <img src="../assets/zs/text.png" alt=""/>
        <p>
          全网独有的Rid跑单功能，全自动对接渠道的api接口，结算方式多样化，可支持订单量，GMV以及服务费等方式来进行结算，节省人工成本以及渠道开支。
        </p>
        <div class="btnbox">
          <div class="banner-btn">
            <button
                class="btn"
                @mouseenter="loginenter('box1')"
                @mouseleave="fn2('box1')"
                @mousedown="experience('a', 'btn1')"
                @mouseup="up('btn1')"
                id="btn1"
            >
              <a href="#/ex">立即体验</a>
            </button>
            <div ref="loginout" class="icon"></div>
          </div>
          <div class="banner-btn">
            <!-- <button
              class="btn"
              @mouseenter="loginenter('box2')"
              @mouseleave="fn2('box2')"
              @click="experience('a', 'btn2')"
              id="btn2"
            >
              <a href="#/order">订购服务</a>
            </button> -->
            <div id="box1" class="icon1"></div>
            <!-- <div id="box2" class="icon2"></div> -->
          </div>
        </div>
      </div>
    </div>
    <div data-v-2e3891b0="" class="pt">
      <div data-v-2e3891b0="" class="pt-box clearFix">
        <div data-v-2e3891b0="" class="pt-info"><h4 data-v-2e3891b0="">平台管理</h4>
          <p data-v-2e3891b0="">支持同时对接淘宝联盟、京东联盟、苏宁联盟、抖音联盟和快手联盟<br data-v-2e3891b0="">无需登录联盟账号工作<br data-v-2e3891b0="">灵活切换，数据分平台统计方便管理
          </p></div>
        <div data-v-2e3891b0="" class="pt-logo clearFix">
          <div data-v-2e3891b0="" class="logo-item"><img style="filter: grayscale(0%)" data-v-2e3891b0="" src="../assets/zs/jd_logo.349766e.jpg"
                                                         alt="京东"> <h5 data-v-2e3891b0="">京东招商系统</h5></div>
          <div style="filter: grayscale(100%)" data-v-2e3891b0="" class="logo-item"><img style="filter: grayscale(100%)" data-v-2e3891b0="" src="../assets/zs/tb_logo.e15854f.jpg"
                                                                                         alt="淘宝"> <h5 data-v-2e3891b0="">淘宝招商系统</h5></div>
          <div style="filter: grayscale(100%)" data-v-2e3891b0="" class="logo-item"><img style="filter: grayscale(100%)" data-v-2e3891b0="" src="../assets/zs/sn_logo.57c1e70.jpg"
                                                                                         alt="苏宁"> <h5 data-v-2e3891b0="">苏宁招商系统</h5></div>
          <div style="filter: grayscale(100%)" data-v-2e3891b0="" class="logo-item"><img style="filter: grayscale(100%)" data-v-2e3891b0="" src="../assets/zs/dy_logo.d6109a2.jpg"
                                                                                         alt="抖音"> <h5 data-v-2e3891b0="">抖音招商系统</h5></div>
          <div  style="filter: grayscale(100%)" data-v-2e3891b0="" class="logo-item"><img data-v-2e3891b0="" src="../assets/zs/ks_logo.bc4d78d.jpg"
                                                                                          alt="快手"> <h5 data-v-2e3891b0="">快手招商系统</h5></div>
        </div>
      </div>
    </div>
    <ul class="zs-list">
      <li class="zs-item" v-for="(item, index) in list" :key="index">
        <div :class="[item.imgbox]" @click="fangda(item.src)"></div>
        <div :class="[item.center, 'zs-item-box']">
          <h3>{{ item.title }}</h3>
          <p>
            {{ item.text }}
          </p>
        </div>
      </li>
    </ul>
  </div>
  <div class="mask" id="mask" @click="enlarge2">
    <img :src="src" alt="" style="width: 80%"/>
  </div>
</template>
<script>
import {defineComponent, reactive, toRefs} from "vue";
import lottie from "lottie-web";

export default defineComponent({
  setup() {
    const state = reactive({
      src: "",
      lottie: {},
      list: [
        {
          title: "rid跑单",
          text: "全网独有的Rid跑单功能，全自动对接渠道的api接口，结算方式多样化，可支持订单量，GMV以及服务费等方式来进行结算，节省人工成本以及渠道开支。",
          center: "left",
          imgbox: "img-box",
          src: require("../assets/help/rid.png"),
        },
        {
          title: "团队管理",
          text: "可对团队进行分组管理，可单独查看团队小组、个人的业务数据以及相关排名，实时数据更新，员工业绩动态实时掌握",
          center: "right",
          imgbox: "img-box",
          src: require("../assets/help/password.png"),
        },
        {
          title: "活动管理",
          text: "支持子账号自主发布招商活动到平台，管理员可自由分配活动对接人，可自由批量审核，实时同步活动数据精确到个人，无需人工统计",
          center: "left",
          imgbox: "img-box2",
          src: require("../assets/zs/activity02.png"),
        },
        {
          title: "商品监控",
          text: "实时监控商品过期、佣金变化重点提示，子账号第一时间了解自己的商品情况，可以做到轻松管理自己的商品",
          center: "right",
          imgbox: "img-box",
          src: require("../assets/zs/goods02.png"),
        },
        {
          title: "订单统计",
          text: "采用招商API实时获取招商订单相关数据，确保数据准确性，即时性展示给员工，可自由下载订单核对",
          center: "left",
          imgbox: "img-box2",
          src: require("../assets/zs/order02.png"),
        },
        {
          title: "知识文库",
          text: "内部招商优秀案例入库管理，提升新员工上手能力，提升文件保存率，轻松管理团队各项文件等",
          center: "right",
          imgbox: "img-box",
          src: require("../assets/zs/knowledge02.jpg"),
        },
        {
          title: "数据统计",
          text: "商品出单排行，员工结算业绩、预估业绩排行，员工动销率统计等多维度数据统计汇总，针对性提升招商实力",
          center: "left",
          imgbox: "img-box",
          src: require("../assets/zs/data2.png"),
        },
      ],
    });
    const fn = (animationData, a) => {
      // console.log(a);
      lottie.destroy();
      state.lottie = lottie.loadAnimation({
        container: document.getElementById(a),
        renderer: "svg",
        loop: false,
        animationData: animationData,
      });
    };
    const loginenter = (a) => {
      fn(require("@/assets/dh/login.json"), a);
    };
    const fn2 = (a) => {
      fn(require("@/assets/dh/loginout.json"), a);
    };
    const experience = (link, a) => {
      lottie.destroy();
      // console.log("跳转页面", link);
      document.getElementById(a).style.backgroundColor = "#3FC1E2";
    };
    const up = (a) => {
      lottie.destroy();
      // console.log("鼠标抬起",a);
      document.getElementById(a).style.backgroundColor = "rgba(0, 0, 0, 0)";
    };
    const fangda = (src) => {
      // console.log('放大');
      state.src = src
      document.getElementById("mask").style.display = "block"

    };
    const enlarge2 = () => {
      // console.log("放大图片");
      document.getElementById("mask").style.display = "none"
    };

    return {
      ...toRefs(state),
      loginenter,
      fn2,
      experience,
      fangda,
      enlarge2,
      up
    };
  },
});
</script>

<style scoped lang="scss">
.home {
  position: relative;
  width: 100%;
  min-width: 1320px;
  height: 1031px;
  background-image: url("../assets/zs/banner.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  margin-bottom: -50px;

  .zs-banner {
    position: absolute;
    width: 100%;
    height: 820px;
    z-index: 1;

    img {
      width: 100%;
      display: block;
    }
  }

  .banner-txt {
    position: relative;
    z-index: 2;
    padding-top: 248px;
    width: 1300px;
    margin: 0 auto;
    box-sizing: border-box;
    color: #fff;

    img {
      height: 160px;
      margin-bottom: 50px;

    }

    p {
      width: 798px;
      height: 138px;
      font-size: 24px;
      font-weight: 500;
      line-height: 45px;
      color: #60e5f3;
    }

    .btnbox {
      display: flex;
      margin-top: 40px;

      .banner-btn {
        position: relative;
        width: 125px;
        height: 49px;
        margin-right: 60px;

        .btn {
          width: 125px;
          height: 49px;
          border: 1px solid #ffffff;
          background-color: rgba($color: #000000, $alpha: 0);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          cursor: pointer;

          a {
            width: 122px;
            height: 45px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 45px;
            color: #ffffff;
            text-decoration: none;
            display: inline-block;
          }
        }

        .icon1 {
          width: 125px;
          height: 49px;
          position: absolute;
          top: 0;
          left: -185px;
          z-index: 1;
        }

        .icon2 {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }
  }
}

.zs-list {
  position: relative;
  height: 5000px;

  .zs-item {
    width: 100%;
    min-width: 1320px;
    height: 931px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    .img-box {
      position: absolute;
      width: 730px;
      // border: 1px solid red;
      height: 360px;
      left: 300px;
      top: 280px;
      cursor: pointer;
    }

    .img-box2 {
      position: absolute;
      width: 730px;
      // border: 1px solid red;
      height: 360px;
      left: 870px;
      top: 280px;
      cursor: pointer;
    }

    .zs-item-box {
      width: 1300px;
      margin: 0 auto;

      h3 {
        font-size: 50px;
        margin-bottom: 25px;
      }

      p {
        font-size: 16px;
      }
    }

    .right {
      padding-top: 365px;
      width: 440px;
      text-align: left;
      padding-left: 830px;
    }

    .left {
      padding-top: 370px;
      width: 440px;
      text-align: left;
      padding-right: 860px;
    }
  }
  .zs-item:nth-child(1) {
    background-image: url("../assets/zs/rid2 (1).png");
    position: absolute;
    top: -60px;
  }
  .zs-item:nth-child(2) {
    background-image: url("../assets/zs/team.png");
    position: absolute;
    top: 800px;
  }

  .zs-item:nth-child(3) {
    background-image: url("../assets/zs/activity.png");
    position: absolute;
    top: 1600px;
  }

  .zs-item:nth-child(4) {
    background-image: url("../assets/zs/goods.png");
    position: absolute;
    top: 2400px;
  }

  .zs-item:nth-child(5) {
    background-image: url("../assets/zs/order.png");
    position: absolute;
    top: 3200px;
  }

  .zs-item:nth-child(6) {
    background-image: url("../assets/zs/knowledge.png");
    position: absolute;
    top: 4000px;
  }

  .zs-item:nth-child(7) {
    background-image: url("../assets/zs/data.png");
    position: absolute;
    top: 4800px;
  }
}

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  border: 1px solid red;
  top: 0;
  left: 0;
  // height: 200px;
  text-align: center;
  background-color: rgba(52, 54, 54, 0.5);

  img {
    margin: 5% 0;
  }

  display: none;
}


.pt[data-v-2e3891b0] {
  width: 100%;
  min-width: 1320px;
  background-color: #CCD8F4;
  padding-bottom: 80px;
  padding-top: 110px;
  height: 234px;
}

.pt .pt-box[data-v-2e3891b0] {
  width: 1300px;
  margin: 0 auto
}

.pt .pt-box .pt-info[data-v-2e3891b0] {
  float: left;
  width: 460px;
  margin-top: 72px
}

.pt .pt-box .pt-info h4[data-v-2e3891b0] {
  font-size: 16px;
  color: #2F66AD;
  font-weight: 600;
  margin-bottom: 20px
}

.pt .pt-box .pt-info p[data-v-2e3891b0] {
  font-size: 14px;
  color: #2F66AD;
  line-height: 28px
}

.pt .pt-box .pt-logo[data-v-2e3891b0] {
  float: right;
  background-color: #ffffff;
  padding: 50px;
  border-radius: 16px
}

.pt .pt-box .pt-logo .logo-item[data-v-2e3891b0] {
  float: left;
  width: 90px;
  margin-left: 50px;
  text-align: center
}

.pt .pt-box .pt-logo .logo-item[data-v-2e3891b0]:first-child {
  margin-left: 0
}

.pt .pt-box .pt-logo .logo-item img[data-v-2e3891b0] {
  width: 100%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px 0 rgba(47, 102, 173, 0.18);
  box-shadow: 0 0 20px 0 rgba(47, 102, 173, 0.18)
}

.pt .pt-box .pt-logo .logo-item h5[data-v-2e3891b0] {
  color: #2F66AD;
  font-size: 14px;
  font-weight: 600;
  margin-top: 30px
}
</style>